<template>
    <div></div>
</template>

<script>
import loading from '@/common/loading';
import swal from '@/common/alerts';

export default {
    name: 'AutenticacaoPorToken',

    created() {
        loading.exibir();
        const { authToken, appName } = this.$route.query;

        const payload = {
            token: authToken,
            nomeAplicacaoOrigem: appName
        };

        this.$store
            .dispatch('auth/loginPorToken', payload)
            // eslint-disable-next-line no-unused-vars
            .then(async (response) => {
                loading.ocultar();
                if (this.$store.getters['auth/autenticado']) {
                    this.$router.push({ name: 'home' });
                } else {
                    swal.exibirMensagemAlerta('Não foi possível realizar a Autenticação integrada.');
                    return;
                }
            })
            .catch((error) => {
                loading.ocultar();
                swal.exibirMensagemErro('Ocorreu algo inesperado ao realizar a Autenticação integrada.');
                console.log(error);
                return;
            });
    }
};
</script>
